<template>
  <div class="">
    <!--主体内容-->
    <div class="content">
      <div class="Wrapper" v-loading="loading">
	      
      	  <div class="content_box" v-for="(item,index) in CommentData" :key="index" :class="{noBorder:index == CommentData.length-1}">
			<div class="box_left">
				<div class="user_head">
					<div class="head_pic"><el-avatar :size="50" :src="item.portrait?item.portrait:circleUrl"></el-avatar></div>
					<div class="head_info">
						  <p class="name">{{item.memberName}}</p>
						  <span>评分</span>
						  <el-rate
						   v-model="item.grade"
						   disabled
						   style="display:inline-block; vertical-align:2px; margin:0 0 0 2px"
						   :colors="['#ff9900', '#ff9900', '#ff9900']"
						  >
						  </el-rate>
						  <span class="state">{{item.parkRate}}</span>
					</div>
				</div>
				<ul class="tag_list" style="margin:0 auto 12px">
					<li v-for="(tag,index) in item.tagList" :key="index">
						<el-tag>{{tag.name}}</el-tag>
					</li>													
				</ul>
				<div class="place">
					<span>{{item.parkName}}</span>
					<span>{{item.updatedTime}}</span>
				</div>
				<p class="comment">{{item.content}}</p>
			</div>
			<div class="box_right">
				<ul class="pic_list">
					<li v-for="(pic,index) in item.imageMap" :key="index">
						<img :src="pic?pic:noPic" width="100%" height="100%" alt="" style="object-fit:contain;"/>
					</li>
				</ul>
			</div>				
		  </div>
		  <div class="noData" v-if="!CommentData.length">
		  		暂无评价
		  </div>
		  
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>	  
	  
    </div>
  </div>
 </div>
</template>
<script>
import // dateFormat
'../../../../common/js/public.js';
import headerImg from "@/assets/img/user_header.png";
import noImg from "@/assets/img/no_pic.png";
export default {
  name: 'parkComment',
  props: {
	datas: {
	   type: Array,
	   default: []
	}			
  },  
  data() {
    return {
		circleUrl: headerImg,
		noPic: noImg,
		page: 1,
		pageSize: 15,
		total: 0,
		loading: false,
		CommentData: []
    }
  },
  methods: {
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.getCommentData();
    },
	getCommentData () {
	  // let url = "/acb/2.0/specialplate/list";
	  let url = "/acb/2.0/bacb/memberParkEvaluation/getMemberEvaluation";
	  this.loading = true;
      this.$axios.get(url, {
          data: {
            page: this.page,
            size: this.pageSize,
            // carId: '',
            // carOwner: '',
            // operationId: '',
			parkId: this.$route.query.parkId
          }
        }).then(res => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
			let data = res.value.list || [];
			
			data.forEach((item, index) => {
			    let grade = item.score / 2
				data[index].grade = grade;
			    let parkRate 
			    if (grade >= 4.5) {
					parkRate = '非常好'
			    } else if (grade < 4.5 && grade >= 4.0) {
					parkRate = '好'
			    } else if (grade < 4.0 && grade >= 3.0) {
					parkRate = '一般'
			    } else if (grade < 3.0 && grade >= 2.0) {
					parkRate = '差'
			    } else if (grade < 2.0 && grade >= 1.0) {
					parkRate = '非常差'
			    } else {
					parkRate = '非常差'				
				}	
				data[index].parkRate = parkRate;	
				let labe = [];
				labe = item.labelMap.split(',');
				let tagArr = [];
			    this.datas.forEach((item, index) => {
					 for (let i = 0; i < labe.length; i++) {
						 if (item.code == labe[i]) {
						 	tagArr.push({name: item.desc})
							break; 
						 }
					 }				    
			    })
				data[index].tagList = tagArr;		
				// data[index].imageMap = {'1': '', '2': 'https://www.baidu.com/img/flexible/logo/pc/result.png'};																	
			})
            this.CommentData = data;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        })	
	}
  },
  created() {
  },
  mounted() {
  	this.getCommentData()
  }
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.Wrapper{
  overflow:hidden;
}
.content_box{
	width:100%;
	margin:0 auto;
	border-bottom:1px dashed #cccccc;
	padding:10px 0;
	box-sizing:border-box;
	overflow:hidden;
}
.noData{
	width:100%;
	color:#888;
	font-size:15px;
	margin:50px auto 0;
	text-align:center
}
.content_box.noBorder{
	border-bottom:none;
}
.content_box .box_left{
	float:left;
	width:55%;
	box-sizing:border-box;
	margin-left:2%;
	margin-right:3%;
}
.box_left .user_head{
	width:100%;
	height:50px;
	margin:0 auto 18px;
}

.box_left .user_head .head_pic{
	float:left;
	height:50px;
	width:50px;
	margin-right:9px;
}
.box_left .user_head .head_info{
	float:left;
}

.box_left .user_head .head_info .name{
	color:#009dd9;
	margin:5px 0 7px;
}

.box_left .user_head .head_info .state{
	font-weight:600;
    text-indent:10px;
	display:inline-block;
}

.box_left .tag_list{
	width:100%;
	height:23px;
	clear:both;
	margin:0 auto 12px;
}

.box_left .tag_list li{
	float:left;
}
.box_left .tag_list li .el-tag{
	margin:0px 8px 0 0;	
	height:23px;
	line-height:22px;
	padding:0 2px;
	font-size:9px;
	border:1px solid #ff0000;
	background:#fff;
	color:#ff0000;
}
.box_left .place{
	width:100%;
	clear:both;
	margin:0 auto 8px;
	color:#333;
}
.box_left .place span{
	display:inline-block;	
	font-size:14px;
}
.box_left .place span:first-child{
	width:60%;	
}
.box_left .place span:last-child{
	width:40%;
}
.box_left .comment{
	font-size:13px;
	line-height:22px;
	color:#555;
}

/*整体*/
.yellow_star{
  display:inline-block;
  position:relative;
  width:90px;
  height:18px;
  top:3px;
  left:2px;
}


.content_box .box_right{
	float:left;
	width:40%;
	box-sizing:border-box;	
}

.box_right .pic_list{
	height:112px;
	margin-top:20px;
}

.box_right .pic_list li{
	display:inline-block;
	width:143px;
	height:112px;
	background:#f6f9ff url('../../../../assets/img/no-pic.png')no-repeat center center;
	margin:0 10px;
	border:1px solid #ececec;
}
</style>
<style>
.content_box .el-rate__item{
	width:19px!important;
}
.content_box .el-rate__icon.el-icon-star-on::before{
	font-size:19px!important;

}

.content_box .el-rate__decimal.el-icon-star-on::before{
	font-size:19px!important;
	color:#ff9900;
}
</style>


