<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <div style="text-align: right">
        <el-button @click="back" type="primary">返回</el-button>
      </div>
    </div>

    <!--主体内容-->
    <div class="content">
      <div class="header_title">查看停车场信息</div>
      <div v-for="(item, index) in helpList" :key="index" class="header_wraper">
        <div class="header" @click="showToggle(index)">
          <h3>
            <i
              class="el-icon-arrow-right"
              style="color: #0099ff; font-weight: 600"
              :class="{ cur1: item.isSubShow }"
            ></i
            >{{ item.title }}
          </h3>
        </div>
        <div v-if="item.isSubShow" class="header_box">
          <div key="one" v-if="index == 0">
            <!--				  <el-row>
					<el-col :span="16">
					  <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;">
						<el-radio-button label="parent">停车场信息</el-radio-button>
						<el-radio-button label="child">下级车场信息</el-radio-button>
					  </el-radio-group>
					</el-col>
				  </el-row>-->

            <el-tabs v-model="tabPosition" type="card">
              <el-tab-pane label="停车场信息" name="parent"> </el-tab-pane>
              <el-tab-pane label="子车场信息" name="child"> </el-tab-pane>
            </el-tabs>

            <!--搜索条件区域-->
            <div class="Wrapper">
              <el-form
                v-if="tabPosition == 'parent'"
                label-position="right"
                label-width="140px"
                :model="formInline"
                style="width: 500px; float: left"
                :ref="'form'"
              >
                <el-form-item label="车场层级：" prop="slaveRelation">
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.slaveRelationName
                  }}</span>
                </el-form-item>
                <el-form-item label="值守模式：" prop="slaveRelation">
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.parkSystemType | parkSystemTypeFilter
                  }}</span>
                </el-form-item>
                <el-form-item label="是否接入远程集控：" prop="remote">
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.remoteControl ? "是" : "否"
                  }}</span>
                </el-form-item>
                <el-form-item
                  label="关联父车场："
                  :prop="formInline.slaveRelation == 2 ? 'parentId' : ''"
                  v-show="formInline.slaveRelation == 2"
                >
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.parentParkName
                  }}</span>
                </el-form-item>
                <el-form-item label="停车场名称：" prop="parkName">
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.parkName
                  }}</span>
                </el-form-item>
                <el-form-item label="停车场编码：" prop="parkAlias">
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.parkAlias
                  }}</span>
                </el-form-item>
                <el-form-item
                  label="停车场物理类型："
                  prop="parkType"
                  style="display: inline-block"
                >
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.closedParkTypeName
                  }}</span>
                </el-form-item>
                <el-form-item
                  :label="
                    formInline.slaveRelation == 1
                      ? '总泊位数:'
                      : '独立车场泊位数'
                  "
                  :prop="formInline.slaveRelation != 1 ? 'amount' : ''"
                >
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.amount
                  }}</span>
                </el-form-item>
                <!-- 临停固定泊位数 -->
                <el-form-item v-show="formInline.tempAmount" label="临停泊位数">
                  <span style="line-height:40px;color:#606266">{{ formInline.tempAmount }}</span>
                </el-form-item>
                <el-form-item v-show="formInline.fixedAmount" label="固定泊位数">
                  <span style="line-height:40px;color:#606266">{{ formInline.fixedAmount }}</span>
                </el-form-item>
                <el-form-item label="固定泊位车辆" v-if="formInline.parkAccessType == 3">
                  <p style="line-height:40px;color:#606266;display:inline-block;" v-for="(item,index) in formInline.fixedBerthCarType"
                      :key="index">
                      <span
                        v-for="(ite, i) in carGroup"
                        :key="i"
                        v-show="item == ite.code"
                      >
                        {{ ite.desc
                        }}{{ index === formInline.fixedBerthCarType.length - 1 ? "" : "、" }}
                      </span>
                  </p>
                </el-form-item>
                <el-form-item
                  v-if="formInline.slaveRelation == 1"
                  label="一级车场泊位数："
                  :prop="formInline.slaveRelation != 1 ? 'amount' : ''"
                >
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.firstLevelAmount
                  }}</span>
                </el-form-item>
                <el-form-item label="区域：" prop="areaId">
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.areaName
                  }}</span>
                </el-form-item>
                <el-form-item label="所属商户：" prop="businessOwner">
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.operationName
                  }}</span>
                </el-form-item>
                <el-form-item label="经纬度：" prop="longitude">
                  <span style="line-height: 40px; color: #606266">{{
                    lnglat
                  }}</span>
                </el-form-item>
                <el-form-item
                  label="地址："
                  prop="parkAddress"
                  style="width: 400px"
                >
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.parkAddress
                  }}</span>
                </el-form-item>
                <el-form-item label="是否商业车场：" prop="businessType">
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.businessTypeName
                  }}</span>
                </el-form-item>
                <el-form-item
                  label="是否承包："
                  prop="contract"
                  v-show="$route.query.parkTypeName == '封闭车场'"
                >
                  <span style="line-height: 40px; color: #606266">
                    {{ formInline.contractName == 0 ? "是" : "否" }}
                  </span>
                </el-form-item>
                <el-form-item label="合作模式：" prop="cooperationModel">
                  <span style="line-height: 40px; color: #606266">
                    {{ formInline.cooperationModelName }}
                  </span>
                </el-form-item>

                <el-form-item label="停车场开放时间：" prop="openStartTime">
                  <span style="line-height: 40px; color: #606266">
                    {{
                      formInline.openStartTime + "~" + formInline.openStopTime
                    }}
                  </span>
                </el-form-item>
                <el-form-item label="商用状态：" prop="commercialStatus">
                  <span style="line-height: 40px; color: #606266">
                    {{ formInline.commercialStatusName }}
                  </span>
                </el-form-item>
                <el-form-item label="车场状态：" prop="parkState">
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.parkStateName
                  }}</span>
                </el-form-item>
                <el-form-item label="是否支持充电: " prop="parkAccessTypeName">
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.chargeable == 0 ? "否" : "是"
                  }}</span>
                </el-form-item>
                <el-form-item label="接入方式: " prop="parkAccessTypeName">
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.parkAccessTypeName
                  }}</span>
                </el-form-item>
                <!--					  <el-form-item label="MS配置方式: " prop="thirdFeeDesc" v-show="formInline.thirdFeeDesc">-->
                <!--						<span style="line-height:40px;color:#606266">{{ formInline.thirdFeeDesc }}</span>-->
                <!--					  </el-form-item>-->
                <el-form-item label="系统编码: " prop="parkCode">
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.parkCode
                  }}</span>
                </el-form-item>
                <el-form-item
                  label="收费规则描述: "
                  prop="thirdFeeDesc"
                  v-show="formInline.thirdFeeDesc"
                >
                  <span style="line-height: 40px; color: #606266">{{
                    formInline.thirdFeeDesc
                  }}</span>
                </el-form-item>
              </el-form>
              <div class="uploadPic" v-if="tabPosition == 'parent'">
                <img v-if="imageUrl" :src="imageUrl" class="avatar2" />
              </div>
              <!--列表区域-->
              <div
                class="tableWrapper"
                v-if="tabPosition == 'child'"
                style="width: 99%"
              >
                <el-table
                  header-cell-class-name="header-call-style"
                  border
                  v-loading="loading"
                  
                  :row-class-name="tableRowClassName"
                  :data="tableData"
                  style="width: 100%"
                >
                  <el-table-column
                    type="index"
                    :label="$t('list.index')"
                    width="70"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    :prop="item.prop"
                    :label="item.label"
                    :width="item.width"
                    v-for="item in tableCols"
                    :key="item.prop"
                    :formatter="item.formatter"
                    align="center"
                  ></el-table-column>
                  <el-table-column v-if="$route.query.parkAccessType != 3" label="同步状态" width :align="'center'">
                    <template slot-scope="scope">
                      <span :class="scope.row.syncStatus | colorFilter">{{
                        scope.row.syncStatus | stateFilter
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="操作"
                    v-if="
                      $route.meta.authority.button.view ||
                      $route.meta.authority.button.edit ||
                      $route.meta.authority.button.configure
                    "
                    align="center"
                    width="80"
                  >
                    <template slot-scope="scope">
                      <AuthorityComponent
                        ComponentName="el-dropdown"
                        align="center"
                        @command="
                          handleCommand($event, scope.row, $route.query.parkId)
                        "
                        width="80"
                      >
                        <el-button type="text" size="small" style="padding: 0">操作<i class="el-icon-arrow-down" /></el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            command="a"
                            v-if="$route.meta.authority.button.edit"
                            >编辑</el-dropdown-item
                          >
                          <el-dropdown-item
                            command="c"
                            v-if="$route.meta.authority.button.configure"
                            >停车场配置</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </AuthorityComponent>
                    </template>
                  </el-table-column>
                </el-table>
                <div v-show="$route.query.parkAccessType != 3" style="margin-top: 10px">
                  <el-button
                    type="primary"
                    @click="updateData()"
                    :loading="updateLoading"
                    >同步本地</el-button
                  >
                </div>
              </div>
              <!--分页器-->
              <!-- <div class="pagerWrapper" v-if="tabPosition == 'child'">
					  <div class="block">
						<el-pagination
						  @current-change="handleCurrentChange"
						  :current-page="page"
						  :page-size="pageSize"
						  layout="total, prev, pager, next, jumper"
						  :total="total"
						></el-pagination>
					  </div>
					</div>-->
            </div>
          </div>
          <div key="two" v-else-if="index == 1">
            <park-comment :datas="spinner"></park-comment>
          </div>
          <div key="three" v-else>
            <owner-comment :datas="spinner"></owner-comment>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat } from "../../../../common/js/public.js";
import parkComment from "./parkComment";
import ownerComment from "./ownerComment";
export default {
  name: "hello",
  data() {
    return {
      carGroup: [],
      helpList: [
        {
          title: "停车场详情",
          isSubShow: true,
        },
        {
          title: "车场评价详情",
          isSubShow: false,
        },
        {
          title: "车主评价详情",
          isSubShow: false,
        },
      ],
      curCount: 0,
      spinner: [],
      updateLoading: false,
      tabPosition: "parent",
      openTime: "",
      fileChang: false,
      parkState: [],
      parkScope: [],
      key1: "",
      parkId: "",
      city: "",
      parkName: "",
      dis: false,
      lnglat: "",
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      uploadData: {
        parkId: "",
      },
      pageSize: 15,
      page: 1,
      total: 0,
      tableData: [],
      workStateAll: [],
      treeData: [],
      parkType: [],
      parkList: "",
      parkParent: [],
      butreeData: [],
      areaName: "",
      businessName: "",
      businessType: [],
      slaveRelation: [],
      cooperationModel: [],
      commercialStatus: [],
      imageUrl: "",
      payMode: [],
      isEdit: false,
      loading: false,
      effective: "1",
      startTime: "",
      defaultProps: {
        label: "label",
        children: "children",
      },
      formInline: {
        parkName: "",
        areaId: "",
        parkCode: "",
        slaveRelation: 0,
        innerPayable: "",
        parentId: "",
        parkAddress: "",
        imageUrl: "",
        amount: "",
        parkType: "",
        businessOwner: "",
        bootTime: new Date(),
        parkState: "",
        mapGrade: "",
        payMode: "",
        cooperationModel: "",
        commercialStatus: 1,
        longitude: "",
        latitude: "",
        businessType: "",
        scopeId: "",
        contract: "",
        openStartTime: "",
        openStopTime: "",
        access: "", // 接入方式
        remoteControl: "",
      },
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "parkCode",
          label: "系统编码",
          width: "",
        },
        {
          prop: "amount",
          label: "泊位数",
          formatter: (row, column, cellValue, index) => {
            if (index == 0 && row.slaveRelation == 1) {
              return row.firstLevelAmount;
            } else {
              return row.amount;
            }
          },
        },
        {
          prop: "slaveRelationName",
          label: this.$t("list.parking_structure"),
        },
        {
          prop: "parentParkName",
          label: "上级停车场",
          formatter: (row, column, cellValue, index) => {
            return cellValue || "--";
          },
        },
        {
          prop: "parkAccessType",
          label: this.$t("list.Access_method"),
          width: "",
          formatter: (row, column, cellValue, index) => {
            switch (cellValue) {
              case 1:
                return "MB云盒";
              case 2:
                return "平台对平台";
              case 3:
                return "轻量化云盒";
              default:
                return "";
            }
          },
        },
        {
          prop: "commercialStatus",
          label: this.$t("list.Commercial_status"),
          width: "",
          formatter: (row, column, cellValue, index) => {
            switch (cellValue) {
              case 1:
                return "下线";
              case 2:
                return "商用";
              case 3:
                return "试运行";
              case 4:
                return "待上线";
              case 5:
                return "废弃";
              default:
                return "未知";
            }
          },
        },
      ],
    };
  },
  filters: {
    stateFilter(state) {
      return state == 1 ? "同步成功" : "同步失败";
    },
    colorFilter(state) {
      return state == 1 ? "color_1" : "color_2";
    },
    parkSystemTypeFilter(type) {
      let data = {
        0: "AI值守",
        1: "有人值守",
        2: "无人值守",
        3: "远程值守",
      };
      return data[type];
    },
  },
  methods: {
    // 获取车辆组列表
    getList() {
      this.$axios
        .get("/acb/2.0/bacb/parkCarGroup/parkCarGroupType", {
          data: {
            // parkId: parkId,
            // type: 1,
          },
        })
        .then((res) => {
          this.carGroup = res.value || [];
        });
    },
    parkSystemType(v) {
      switch (v) {
        case 0:
          return "AI值守";
        case 1:
          return "有人值守";
        case 2:
          return "无人值守";
        case 3:
          return "远程值守";
        default:
          return "";
      }
    },
    getSpinnerData() {
      // let url = '/acb/2.0/park/' + this.$route.query.parkId;
      let url = "/acb/2.0/bacb/memberParkEvaluation/spinner";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.spinner = res.value.parkEvalutionEnum || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 切换显示
    showToggle(num) {
      this.curCount = num;
      this.helpList.forEach((item, index) => {
        if (index == num) {
          item.isSubShow = !item.isSubShow;
        } else {
          item.isSubShow = false;
        }
      });
    },
    // 同步本地
    updateData() {
      this.updateLoading = true;
      this.$axios
        .post("/acb/2.0/bacb/park/sync/" + this.$route.query.parkId, {
          data: {
            topParkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          this.updateLoading = false;
          if (res.state == 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.page = 1;
              this.searchData();
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch(() => {
          this.updateLoading = false;
        });
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    secondParkConfig(data) {
      this.$setAuthority(
        "secondParkConfig",
        this.$route.meta.authority.subPage.configure
      );
      // console.log(this.$route.meta.authority, 11);
      // sessionStorage.subPageAuthority = JSON.stringify(this.$route.meta.authority.subPage.configure);
      // if (data.parkTypeName == "封闭车场") {
      this.$router.push({ path: "/secondParkConfig", query: data });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      let url = "/acb/2.0/bacb/park/children/" + this.$route.query.parkId;
      this.$axios.get(url).then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.total = res.value.total * 1;
          let tableData = [res.value[res.value.length - 1]];
          tableData = tableData.concat(
            res.value.slice(0, res.value.length - 1)
          );
          this.tableData = tableData;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 返回
    back() {
      this.$router.go(-1);
    },
    getParkDetail(parkId) {
      let url = "/acb/2.0/bacb/park/getParkVOById/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.formInline = data;
          this.areaName = data.areaName;
          this.businessName = data.operationName;
          this.lnglat =
            data.longitude / 1000000 + "," + data.latitude / 1000000;
          this.openTime = [
            new Date(this.formInline.openStartTime),
            new Date(this.formInline.openStopTime),
          ];
          // this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    handleCommand(cmd, data, parentId) {
      if (cmd == "a") {
        // 编辑
        let path = "/outAddClosePark";
        this.$router.push({ path: path, query: data });
      } else if (cmd == "b") {
        // 查看
        let path = "/outCloseParentDetail";
        if (data.index == 0) {
          this.tabPosition = "parent";
        } else {
          this.$router.push({ path: path, query: data });
        }
        // this.$router.push({ path: path, query: {} });
      } else if (cmd == "c") {
        // 配置
        let path = "/secondParkConfig";
        if (data.index == 0) {
          path = this.$route.query.parkAccessType == 3 ? "/outParkLightConfig" : "/outParkConfig";
        };
        this.$setAuthority(
          "secondParkConfig",
          this.$route.meta.authority.subPage.configure
        );
        this.$router.push({
          path: path,
          query: Object.assign({}, data, { parentId: parentId }),
        });
      }
    },
    getParkImg(parkId) {
      let url = "/acb/2.0/bacb/park/getImagebyId/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.imageUrl = res.value;
          // this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
  },
  components: {
    parkComment,
    ownerComment,
  },
  created() {
    this.getList();
    this.getParkImg(this.$route.query.parkId);
    this.parkId = this.$route.query.parkId;
    this.getParkDetail(this.$route.query.parkId);
    this.getSpinnerData();
  },
  mounted() {
    // if (sessionStorage.parentDetail == "child") {
    //   this.tabPosition = "child";
    // }
  },
  watch: {
    tabPosition: function (val) {
      sessionStorage.parentDetail = val;
      if (val == "child") {
        this.searchData();
      }
      return val;
    },
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.Wrapper {
  overflow: hidden;
  border: 0px solid #ccc;
  border-radius: 8px;
  padding: 20px 20px;
}

.breadcrumb {
  height: 35px;
  margin -12px 0 12px 0
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  border-radius: 4px;
  padding: 20px 0;
}

.header_wraper {
  width: 100%;
}

.header {
  width: 100%;
  height: 42px;
  line-height: 42px;
  background: #f9f9f9;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
}

.header:nth-child(1) {
  border-top: none;
}

.header i {
  -webkit-transform-origin: 50% 50%;
  margin-right: 2px;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 10px;
}

.cur1 {
  -webkit-transition: all 0.1s linear;
  -webkit-transform: rotate(90deg);
  top: 14px !important;
}

.header_title {
  width: 100%;
  height: 42px;
  line-height: 42px;
  background: #0f6eff;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-indent: 12px;
}

.header_box {
  width: 100%;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-top: none;
  padding: 10px 10px;
}

.uploadPic {
  float: left;
  margin-left: 150px;
  width: 295px;
  height: 178px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;

  img {
    width: 100%;
    height: 100%;
  }
}

.color_1 {
  color: #333333;
}

.color_2 {
  color: #D9001B;
}
</style>
<style>
.dialog_button {
  width: 100%;
  height: 40px;
}
</style>
